import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./assets/sass/_avatar.scss"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'maz-ui/styles' // or import 'maz-ui/css/main.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import { defineRule, ErrorMessage } from 'vee-validate';
// import { required, email } from '@vee-validate/rules';

library.add(fas,faTwitter,faFontAwesome)
// defineRule('required',required)
// defineRule('email',email)
const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(store)
app.use(router)
app.use(VueSweetalert2);
// app.component('ErrorMessage', ErrorMessage)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.mount("#app")


// createApp(App).use(store).use(router).mount("#app");

